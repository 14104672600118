import React from "react";

export const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="15"
    viewBox="0 0 20.385 16.582"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.258"
        y1="0.124"
        x2="0.74"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#656565" />
        <stop offset="1" stopColor="#3c3c3c" stopOpacity="0.467" />
      </linearGradient>
    </defs>
    <path
      d="M10.027,5.137a.036.036,0,0,1,.02,0,4.189,4.189,0,0,1,7.134-3.819A8.372,8.372,0,0,0,19.394.543c.144-.072.292-.14.435-.22a4.175,4.175,0,0,1-1.318,1.921A4.211,4.211,0,0,1,18,2.6c.012,0,.024,0,.036,0L18,2.62a8.506,8.506,0,0,0,1.969-.483c.14-.048.28-.1.419-.152A8.534,8.534,0,0,1,18.312,4.13c.008.18.012.36.012.543A11.828,11.828,0,0,1,6.415,16.582,11.859,11.859,0,0,1,0,14.7a8.227,8.227,0,0,0,1,.06,8.406,8.406,0,0,0,4.278-1.17,8.66,8.66,0,0,0,.919-.6c-.008,0-.016,0-.024,0,.008,0,.016-.012.024-.016A4.181,4.181,0,0,1,2.557,10.7a4.28,4.28,0,0,1-.26-.639,4.143,4.143,0,0,0,.775.072,4.159,4.159,0,0,0,.807-.088c.1-.012.2-.024.292-.04l-.036-.012c.012,0,.028,0,.04-.008A4.187,4.187,0,0,1,.819,5.88V5.828a4.113,4.113,0,0,0,1.49.483c.132.02.264.044.4.052-.008-.008-.016-.012-.024-.02.012,0,.024,0,.036,0A4.161,4.161,0,0,1,1.294,1.019c.044-.084.084-.168.132-.252A11.882,11.882,0,0,0,9,5.025c.34.048.679.092,1.027.112C10.027,5.141,10.027,5.137,10.027,5.137Z"
      fill="url(#linear-gradient)"
    />
  </svg>
);
