import React from "react";

export const Plus = ({ size = 24, color = "#000000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 14 14"
  >
    <g
      id="グループ_1321"
      data-name="グループ 1321"
      transform="translate(-318 -1935.689)"
    >
      <path
        id="パス_1435"
        data-name="パス 1435"
        d="M12312.413,2771.5h14"
        transform="translate(-11994.413 -828.811)"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
      <path
        id="パス_1437"
        data-name="パス 1437"
        d="M0,0H14"
        transform="translate(325 1935.689) rotate(90)"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
    </g>
  </svg>
);
