import React from "react";
import ApolloClient from "apollo-boost";
import {ApolloProvider} from "@apollo/react-hooks";
import {AnchorProvider} from "../atoms/anchor/AnchorProvider";
import {WindowSizeProvider} from "../atoms/core/WindowSizeProvidor";
const client = new ApolloClient({
    uri: "https://suprieve.com/blog/graphql",
});
export const Layout = ({children}) =>
    <ApolloProvider client={client}>
        <AnchorProvider>
            <WindowSizeProvider>
                {children}
            </WindowSizeProvider>
        </AnchorProvider>
    </ApolloProvider>;