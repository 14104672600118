import React, { useEffect, useMemo, useRef, useState } from "react";
import * as styles from "./FadeIn.module.scss";

interface IProps {
  children: React.ReactElement;
  duration?: string;
  delay?: string;
  [x: string]: any;
}

const FadeIn: React.FC<IProps> = ({
  children,
  duration,
  delay,
  ...rest
}: IProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect((): void => {
    const observer = new IntersectionObserver(entries => {
      if (!entries[0].isIntersecting) return;
      setVisible(true);
      observer.unobserve(ref.current);
    });

    observer.observe(ref.current);

    return () => {
      if (!ref.current) return;
      observer?.unobserve(ref.current);
    };
  }, []);

  const style = useMemo(
    () => ({
      transitionDuration: duration || "1s",
      transitionDelay: delay || "0s",
    }),
    [duration, delay]
  );

  // console.log(styles);

  return (
    <div
      ref={ref}
      className={`${styles.fadein} ${isVisible ? styles.active : ""}`}
      style={style}
      {...rest}
    >
      {children}
    </div>
  );
};

export default React.memo(FadeIn);
