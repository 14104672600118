import React from "react";
import PageInnerNavigator, {
  PageInnerNavigatorItemProps,
} from "./careers/new/pageInnerNavigator";

const links: PageInnerNavigatorItemProps[] = [
  {
    anchor: "#About",
    name: "はじめに",
  },
];

export const NewTopHeader = () => <PageInnerNavigator items={links} />;
