import React, { useEffect } from "react";
import * as styles from "./TopPopupMenu.module.scss";
import { TopMenuCompany } from "./TopMenuCompany";
import { TopMenuServices } from "./TopMenuServices";
import { TopMenuCareer } from "./TopMenuCareer";

const menus = {
  company: <TopMenuCompany />,
  services: <TopMenuServices />,
  careers: <TopMenuCareer />,
};

const paddingTop = 40;

export const TopPopupMenu = ({
  page,
  top,
  right,
  dismiss,
  onMouseOut,
  onMouseOver,
  show,
}) => {
  const element = menus[page];
  if (!element) {
    return null;
  }

  useEffect(() => {
    document.body.addEventListener("click", dismiss);
    return () => {
      document.body.removeEventListener("click", dismiss);
    };
  });
  return (
    <div
      className={`${styles.menuContainer} ${show ? styles.show : ""}`}
      style={{ paddingTop, marginTop: -1 * paddingTop, top, right }}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    >
      <div className={styles.menuPopup}>{element}</div>
    </div>
  );
};
