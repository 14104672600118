import React, { ReactNode } from "react";
import Footer from "../core/Footer";
import { FooterSp } from "../core/FooterSp";
import { RecruitNewbiesHeader } from "../menus/RecruitNewbiesHeader";
import { Layout } from "../Layout";
import { StaticImage } from "gatsby-plugin-image";
import newbiewscover from "../../images/career_new_top/_kpo0802_newgraduate_head.jpg";
import * as styles from "../../styles/careers/whysuprieve.module.scss";

interface RecruitNewbiewsLayoutProps {
  children: ReactNode;
  extraHeader?: ReactNode;
  current: string;
  isSp?: boolean;
  isCareerInterview?: boolean;
}

export const RecruitNewbiewsNoLayout: React.FC<RecruitNewbiewsLayoutProps> = ({
  children,
  extraHeader,
  current,
  isSp = false,
  isCareerInterview = false,
}) => (
  <>
    <RecruitNewbiesHeader
      isSp={isSp}
      current={current}
      extraHeader={extraHeader}
      isCareerInterview={isCareerInterview}
    />
    <img
      data-career-top-image="data-career-top-image"
      className={styles.cover}
      src={newbiewscover}
      alt=""
    />
    {children}
    <Footer />
    <FooterSp />
  </>
);

export const RecruitNewbiewsLayout: React.FC<RecruitNewbiewsLayoutProps> = ({
  children,
  extraHeader,
  current,
  isSp = false,
}) => (
  <Layout>
    <RecruitNewbiewsNoLayout
      isSp={isSp}
      current={current}
      extraHeader={extraHeader}
    >
      {children}
    </RecruitNewbiewsNoLayout>
  </Layout>
);
