// extracted by mini-css-extract-plugin
export var news = "J_gh";
export var cover = "J_dS";
export var title = "J_c";
export var leftcontener = "J_mv";
export var frame = "J_mw";
export var categoryInformation = "J_mx";
export var categoryPressRelease = "J_my";
export var categoryMedia = "J_mz";
export var item = "J_mB";
export var date = "J_mC";
export var frame1 = "J_mD";
export var frame2 = "J_mF";
export var frame3 = "J_mG";
export var frame4 = "J_mH";
export var name = "J_hz";
export var gridcontainer = "J_mJ";
export var box = "J_mK";
export var rightcontainer = "J_mL";
export var toukou = "J_mM";
export var b = "J_mN";
export var a = "J_mP";
export var strong = "J_mQ";
export var content = "J_br";
export var postImg = "J_mR";
export var container = "J_gl";
export var background = "J_mS";
export var textArea = "J_mT";
export var tag = "J_mV";
export var newsTitle = "J_mW";
export var blogParts = "J_mX";
export var blogPartsCareer = "J_mY";
export var blogPartsItemWrapper = "J_mZ";
export var blogPartsItem = "J_m0";
export var inner = "J_mq";
export var flex1 = "J_hk";