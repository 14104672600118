import React, { useEffect, useState } from "react";
import * as styles from "../../../styles/careers/career.module.scss";
import { RecruitNewbiewsLayout } from "../../../organisms/careers/RecruitNewbiewsLayout";
import { Link } from "gatsby";
import { NewsComponentsCareer } from "../../../organisms/news/NewsComponentsCareer";
import { NewTopHeader } from "../../../organisms/menus/NewTopHeader";
import { SEO } from "../../../atoms/seo/SEO";
import seoImage from "../../../images/career_new_top/_kpo0802_newgraduate_head.jpg";
import MessageImg2 from "../../../images/career_new_top/companyOverview.png";
import MessageImg1 from "../../../images/career_new_top/_kpo1145_re.jpg";
import ReasonImg2 from "../../../images/career_new_top/working_environment.png";
import ReasonImg1 from "../../../images/career_new_top/_kpo0813.jpg";
import FadeIn from "../../../atoms/fadein/FadeIn";

export const MAX_WINDOW_HEIGHT = 960;
export const THLESHOLD_WIDTH_SP = 821;

export default ({ location: { pathname } }) => {
  const TOP_OTHER_DOM_HEIGHT = 144;
  let innerHeight = MAX_WINDOW_HEIGHT;
  if (typeof window !== "undefined") {
    innerHeight = window.innerHeight;
  }

  const [titleHeight, setTitleHeight] = useState(innerHeight);
  const [isSp, setIsSp] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsSp(window.innerWidth < THLESHOLD_WIDTH_SP);

      if (!isSp) {
        const topImageDom = document.querySelector(
          "[data-career-top-image=data-career-top-image]"
        );
        const topImageDomHeight = topImageDom?.getBoundingClientRect().height;
        const innerHeight =
          window.innerHeight <= MAX_WINDOW_HEIGHT
            ? window.innerHeight
            : MAX_WINDOW_HEIGHT;

        if (topImageDomHeight) {
          setTitleHeight(
            innerHeight - topImageDomHeight - TOP_OTHER_DOM_HEIGHT
          );
        }
      }
    }, 300);

    window.addEventListener("resize", () => {
      setIsSp(window.innerWidth < THLESHOLD_WIDTH_SP);

      setTimeout(() => {
        if (!isSp) {
          const topImageDom = document.querySelector(
            "[data-career-top-image=data-career-top-image]"
          );
          const topImageDomHeight = topImageDom?.getBoundingClientRect().height;
          const innerHeight =
            window.innerHeight <= MAX_WINDOW_HEIGHT
              ? window.innerHeight
              : MAX_WINDOW_HEIGHT;

          if (topImageDomHeight) {
            setTitleHeight(
              innerHeight - topImageDomHeight - TOP_OTHER_DOM_HEIGHT
            );
          }
        }
      }, 300);
    });
  }, []);

  return (
    <RecruitNewbiewsLayout isSp={isSp} current="top">
      <SEO
        pathname={pathname}
        title="新卒採用 | New graduate"
        description="創業から、企画開発、人材、メディア、コンサル、システム、福祉など多彩な14の事業を展開し、・15年間連続増収増益・2022年卒版就職企業人気ランキング 人材部門【3位】・2021年アジア急成長ランキングトップ1000にて【103位】・2019年ホワイト企業認定・2018年アジア急成長ランキングトップ1000にて【103位】、【日本14位】、【未上場1位】に選出・2017年ベストベンチャー100 受賞 など、たくさんのメディアに取り上げていただいています。"
        image={seoImage}
      />

      <FadeIn>
        <div
          className={styles.graduate}
          style={!isSp ? { height: titleHeight } : {}}
        >
          <h2>New graduate</h2>
          <h3>新卒採用（総合職）</h3>

          <div className={styles.blog}>
            <div className={styles.blogContainerLast}>
              <div className={styles.blogContainerLeft}>
                <h2>
                  <span>New</span>2022/6/1
                </h2>
              </div>
              <div className={styles.blogContainerRight}>
                <h3>2023卒 総合職採用スタート</h3>
              </div>
            </div>
          </div>
        </div>
      </FadeIn>

      <NewTopHeader />

      <div className={styles.first}>
        <div className={styles.firstText}>
          <div className={styles.slideWrap}>
            <iframe
              className={`speakerdeck-iframe ${styles.slide}`}
              frameBorder="0"
              src="https://speakerdeck.com/player/c4989b7deaf4457291d1f2adb7f23ea8?slide=1"
              title="【23卒】HD総合職採用"
              data-ratio="1.78343949044586"
            ></iframe>
          </div>
        </div>
      </div>

      <div className={styles.news}>
        <div className={styles.topTitle}>
          <h2>Suprieve NOW!</h2>
          <h3>Suprieveに関する情報、ニュース、最新動向を随時掲載しています</h3>
        </div>

        <div className={styles.nowNews}>
          <div className={styles.container}>
            <div className={styles.pconly}>
              <NewsComponentsCareer limit={3} />
            </div>
            <div className={styles.sponly}>
              <NewsComponentsCareer limit={4} />
            </div>
            <div className={styles.newsArchive}>
              <Link to="/careers/new/suprieve-now/">View All</Link>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.reason}>
        <div className={styles.reasonTitle}>
          <h2>Why Suprieve?</h2>
          <h3>Suprieveで働く理由</h3>
        </div>
        <div className={styles.reasonContainer}>
          <Link to="/company/culture/">
            <img
              className={styles.reasonImg}
              src={ReasonImg1}
              alt="cultureのイメージ写真"
            />
          </Link>
          <div className={styles.reasonContent}>
            <div className={styles.reasonContentWrapper}>
              <h2>Culture</h2>
              <h4>カルチャー</h4>
              <h3>
                最強のチームから
                <br />
                この上ない最高の世界が始まる
              </h3>

              <div className={styles.viewAll}>
                <Link to="/company/culture/">View All</Link>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.reasonContainer}>
          <Link to="/careers/new/why-suprieve/">
            <img
              className={styles.reasonImg}
              src={ReasonImg2}
              alt="working environmentのイメージ写真"
            />
          </Link>
          <div className={styles.reasonContentTwo}>
            <div className={styles.reasonContentWrapper}>
              <h2>Working Environment</h2>
              <h4>働く環境</h4>
              <h3>キャリア開発を支援する仕組み</h3>

              <div className={styles.viewAll}>
                <Link to="/careers/new/why-suprieve/">View All</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.about}>
        <div className={styles.aboutTitle}>
          <h2>About Suprieve</h2>
          <p>Suprieveについて</p>
        </div>

        <div>
          <div className={styles.messageContainer}>
            <div className={styles.massageContainerLeft}>
              <Link to="/company/ceo-message/">
                <img className={styles.messageImg} src={MessageImg1} alt="" />
              </Link>
              <h2>CEO Message</h2>
              <h3>代表メッセージ</h3>
              <div className={styles.viewAllGray}>
                <Link to="/company/ceo-message/">View All</Link>
              </div>
            </div>

            <div className={styles.massageContainerRight}>
              <Link to="/company/overview/">
                <img className={styles.messageImg} src={MessageImg2} alt="" />
              </Link>
              <h2>Company Overview</h2>
              <h3>会社概要・拠点</h3>
              <div className={styles.viewAllGray}>
                <Link to="/company/overview/">View All</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className={styles.link}>
          <div className={styles.linkLeft}>
            <Link to="/careers/new/opportunities/">
              <h2>Career Opportunities</h2>
              <p>採用情報</p>
            </Link>
          </div>

          <div className={styles.linkCenter}>
            <Link to="/careers/new/internship/">
              <h2> Internship</h2>
              <p>インターンシップ制度について</p>
            </Link>
          </div>

          <div className={styles.linkRight}>
            <Link to="/careers/new/entry/">
              <h2>Entry</h2>
              <p>新卒採用エントリー</p>
            </Link>
          </div>
        </div>
      </div>
    </RecruitNewbiewsLayout>
  );
};
function setTitleHeight(arg0: number) {
  throw new Error("Function not implemented.");
}
