import React from "react";

export const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="21"
    viewBox="0 0 12.387 23.854"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.253"
        y1="0.04"
        x2="0.89"
        y2="0.951"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#656565" />
        <stop offset="1" stopColor="#3c3c3c" stopOpacity="0.467" />
      </linearGradient>
    </defs>
    <path
      id="FACEBOOK風のアイコン素材"
      d="M12.387,3.96H10.141c-1.765,0-2.1.837-2.1,2.063V8.733h4.2l-.547,4.242H8.037V23.854H3.662V12.967H0V8.724H3.662V5.6C3.662,1.972,5.874,0,9.114,0a30.759,30.759,0,0,1,3.273.166V3.96Z"
      fill="url(#linear-gradient)"
    />
  </svg>
);
