import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import * as styles from "../menus/menus.module.scss";
import { Hamburger } from "../../atoms/graphics/Hamburger";
import { LogoSp } from "../../atoms/graphics/LogoSp";

const links: ILink[] = [
  {
    id: "company",
    label: "Company",
    href: "/#Company",
  },
  {
    id: "company",
    label: "Services",
    href: "/#Services",
  },
  {
    id: "news",
    label: "News",
    href: "https://suprieve.com/blog/",
  },
  {
    id: "careers",
    label: "Careers",
    href: "/#Career",
  },
  {
    id: "contact",
    label: "Contact",
    href: "/contact",
  },
];

function getNewsUrl(host: string): string {
  const isStg = host.includes("staging");
  const isLocal = host.includes("localhost");

  return isStg || isLocal
    ? "https://staging.suprieve.com/blog/"
    : "https://suprieve.com/blog/";
}

export const GlobalHeader = ({
  toggleSpMenu,
  onMenuMouseOver,
  onMenuMouseOut,
  isWhite = false,
  isCareerPage = false,
  isHistoryPage = false,
  isWhiteBackground = false,
}) => {
  if (typeof window === "undefined") {
    return null;
  }

  const host = window.location.host;
  const [_, base] = window.location.pathname.split("/");

  // document.body.addEventListener('click', dismiss) でポップアップ閉じてしまうのでバブリングを止める
  const handleClickLink = label => e => {
    e.stopPropagation();
    onMenuMouseOver(e, label.toLowerCase());
  };

  return (
    <div>
      <div
        className={classNames(
          styles.globalBackground,
          isCareerPage ? styles.globalBackground__white : "",
          isWhiteBackground ? styles.globalBackground__whiteBackground : ""
        )}
      >
        <div className={styles.globalList}>
          <div className="flex-1 sp-only" />
          <Link to="/">
            <LogoSp
              className={styles.logo}
              color={isWhite ? "#ffffff" : "#000000"}
            />
          </Link>
          <div className="flex-1" />
          <ul
            className={["pc-only", isWhite ? "" : styles.blackFont].join(" ")}
          >
            {links.map(({ href, label }, i) => {
              let link = href;
              if (
                "/careers/diversity" === location.pathname &&
                href === "/contact"
              ) {
                link += "?type=diversity";
              }
              return label === "Contact" ? (
                <li
                  key={`recruit-new-menu-${i}`}
                  onMouseOver={e => onMenuMouseOver(e, label.toLowerCase())}
                  onMouseOut={onMenuMouseOut}
                >
                  <Link
                    to={href}
                    className={
                      label.toLowerCase() === base ? styles.current : ""
                    }
                    onClick={handleClickLink(label)}
                  >
                    {label}
                  </Link>
                </li>
              ) : (
                <li
                  key={`recruit-new-menu-${i}`}
                  onMouseOver={e => onMenuMouseOver(e, label.toLowerCase())}
                  onMouseOut={onMenuMouseOut}
                >
                  <>
                    {label === "News" ? (
                      <a
                        className={
                          label.toLowerCase() === base ? styles.current : ""
                        }
                        href={getNewsUrl(host)}
                      >
                        {label}
                      </a>
                    ) : (
                      <button
                        className={
                          label.toLowerCase() === base ? styles.current : ""
                        }
                        onClick={handleClickLink(label)}
                      >
                        {label}
                      </button>
                    )}
                  </>
                </li>
              );
            })}
          </ul>

          <div className={`sp-only ${styles.spMenu}`} onClick={toggleSpMenu}>
            <Hamburger
              color={isWhite ? "#ffffff" : undefined}
              size={32}
              className={styles.humburger}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
