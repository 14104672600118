import React from "react";

export const YoutubeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15"
    viewBox="0 0 22.32 15.694"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#656565" />
        <stop offset="1" stopColor="#3c3c3c" stopOpacity="0.467" />
      </linearGradient>
    </defs>
    <path
      d="M22.9,6.956A2.8,2.8,0,0,0,20.93,4.969C19.19,4.5,12.21,4.5,12.21,4.5s-6.98,0-8.72.469A2.8,2.8,0,0,0,1.516,6.956a29.421,29.421,0,0,0-.466,5.407,29.421,29.421,0,0,0,.466,5.407A2.763,2.763,0,0,0,3.49,19.724c1.741.469,8.72.469,8.72.469s6.98,0,8.72-.469A2.763,2.763,0,0,0,22.9,17.77a29.421,29.421,0,0,0,.466-5.407A29.421,29.421,0,0,0,22.9,6.956ZM9.927,15.681V9.044l5.834,3.319L9.927,15.681Z"
      transform="translate(-1.05 -4.5)"
      fill="url(#linear-gradient)"
    />
  </svg>
);
