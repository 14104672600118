import React from "react";
import { POSTS_WITH_LIMIT } from "./news-queries";
import { useQuery } from "@apollo/react-hooks";
import { categoryClasses, WpPost } from "./NewsItem";
import * as styles from "./news.module.scss";
import { format } from "date-fns";
import { Link } from "gatsby";
import { compact } from "lodash";

export const NewsComponentsCareer = ({ limit }) => {
  const { loading, error, data } = useQuery(POSTS_WITH_LIMIT, {
    variables: { limit },
  });
  return (
    <div className={styles.blogPartsCareer}>
      {error && <span>ニュースの読み込みに失敗しました</span>}
      {!loading &&
        !error &&
        data.posts.edges.map((node, i) => {
          const post = new WpPost(node);
          return (
            <div
              key={`blog-parts-${i}`}
              className={styles.blogPartsItemWrapper}
            >
              <Link to={`/blog/articles/${post.slug}`}>
                <div className={styles.blogPartsItem}>
                  <img
                    className={styles.postImg}
                    src={post.src}
                    srcSet={post.srcSet}
                    sizes={post.sizes}
                  />
                  <h2>{post.title}</h2>
                  <div className={styles.inner}>
                    <p
                      className={compact([
                        styles.frame,
                        categoryClasses[post.categoryName],
                      ]).join(" ")}
                    >
                      {post.categoryName}
                    </p>
                    <div className={styles.flex1}> </div>
                    <span className={styles.date}>
                      {format(new Date(post.date), "yyyy/MM/dd")}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
    </div>
  );
};
