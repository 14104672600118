// extracted by mini-css-extract-plugin
export var MenuManagement = "X_wf";
export var MenuManagement__scrollable = "X_wg";
export var MenuManagement__reFixed = "X_wh";
export var MenuManagement__downwardScroll = "X_wj";
export var MenuManagement__upwardScroll = "X_wk";
export var MenuManagement__globalNavigation = "X_wl";
export var MenuManagement__careerNavigation = "X_wm";
export var MenuManagement__careerNavigation_interview = "X_wn";
export var MenuManagement__careerNavigation_downwardScroll = "X_wp";
export var MenuManagement__historyNavigation = "X_wq";