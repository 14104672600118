import React, { useEffect, useState } from "react";
import * as styles from "./footerSp.module.scss";
import { Link } from "gatsby";
import { TwitterIcon } from "../../atoms/graphics/TwitterIcon";
import { InstagramIcon } from "../../atoms/graphics/InstagramIcon";
import { FacebookIcon } from "../../atoms/graphics/FacebookIcon";
import { YoutubeIcon } from "../../atoms/graphics/YoutubeIcon";
import LogoBlack from "../../images/logo_black.png";
import {
  getLocationTreeInfo,
  locationInfoProps,
} from "../../utils/locationTree";
import { Minus } from "../../atoms/graphics/minus";
import { Plus } from "../../atoms/graphics/plus";
import classNames from "classnames";

type FooterSpProps = {
  onClick?(): void;
  className?: string;
};

function getNewsUrl(host: string): string {
  const isStg = host.includes("staging");
  const isLocal = host.includes("localhost");

  return isStg || isLocal
    ? "https://staging.suprieve.com/blog/"
    : "https://suprieve.com/blog/";
}

export const FooterSp: React.FC<FooterSpProps> = ({ className = "" }) => {
  const scrollToPageTop = () => {
    scrollTo({ top: 0, behavior: "smooth" });
  };

  if (typeof window === "undefined") {
    return null;
  }

  const host = window.location.host;

  const [windowHref, setWindowHref] = useState("");
  const [windowProtocol, setWindowProtocol] = useState("");
  const [windowHost, setWindowHost] = useState("");

  useEffect(() => {
    setWindowHref(window.location.href);
    setWindowProtocol(window.location.protocol);
    setWindowHost(window.location.host);
  }, []);

  const locationTreeInfo = getLocationTreeInfo(
    windowHref,
    windowProtocol,
    windowHost
  );

  const [isDisplayedCompany, setIsDisplayedCompany] = useState(false);
  const [isDisplayedService, setIsDisplayedService] = useState(false);
  const [isDisplayedCareer, setIsDisplayedCareer] = useState(false);
  const [isDisplayedCareerNew, setIsDisplayedCareerNew] = useState(false);
  const [isDisplayedCareerMid, setIsDisplayedCareerMid] = useState(false);

  return (
    <footer className={className} style={{ pointerEvents: "auto" }}>
      <div className={styles.FooterSp}>
        <div className={styles.FooterSp__columnUpper}>
          <div className={styles.FooterSp__pageTopNavigation}>
            <button onClick={scrollToPageTop}>
              <p className={styles.FooterSp__verticalLine} />
              <p className={styles.FooterSp__pageTopNavigationText}>Page Top</p>
            </button>
          </div>
          <p className={styles.FooterSp__companyLogo}>
            <Link to="/">
              <img src={LogoBlack} alt="Suprieve Holdings" height={32} />
            </Link>
          </p>
          <ul className={styles.FooterSp__breadCrumb}>
            {locationTreeInfo.map((location, index) => (
              <li key={index} className={styles.FooterSp__breadCrumbItem}>
                {index !== 0 ? (
                  <p className={styles.FooterSp__breadCrumbItemTriangle} />
                ) : null}
                <p
                  className={classNames(
                    styles.FooterSp__breadCrumbItemText,
                    index === locationTreeInfo.length - 1
                      ? styles.FooterSp__breadCrumbItemText_current
                      : ""
                  )}
                >
                  {index === locationTreeInfo.length - 1 ? (
                    <span>{location.location}</span>
                  ) : (
                    <Link to={location.path}>{location.location}</Link>
                  )}
                </p>
              </li>
            ))}
          </ul>
        </div>

        <div className={styles.FooterSp__columnMiddle}>
          <div className={styles.FooterSp__snsNavigation}>
            <p className={styles.FooterSp__snsNavigationTitle}>Follow Us</p>
            <ul className={styles.FooterSp__snsNavigationButtons}>
              <li className={styles.FooterSp__snsNavigationButton}>
                <a
                  className={styles.FooterSp__snsNavigationButtonLink}
                  href="https://twitter.com/Suprieve8989"
                  target="_blank"
                >
                  <TwitterIcon />
                </a>
              </li>
              <li className={styles.FooterSp__snsNavigationButton}>
                <a
                  className={styles.FooterSp__snsNavigationButtonLink}
                  href="https://www.facebook.com/suprieve/"
                  target="_blank"
                >
                  <FacebookIcon />
                </a>
              </li>
              <li className={styles.FooterSp__snsNavigationButton}>
                <a
                  className={styles.FooterSp__snsNavigationButtonLink}
                  href="https://www.instagram.com/suprieve/"
                  target="_blank"
                >
                  <InstagramIcon />
                </a>
              </li>
              <li className={styles.FooterSp__snsNavigationButton}>
                <a
                  className={styles.FooterSp__snsNavigationButtonLink}
                  href="https://www.youtube.com/channel/UC24yp_X3thkh9dmEVxev1-w"
                  target="_blank"
                >
                  <YoutubeIcon />
                </a>
              </li>
            </ul>
          </div>

          <div className={styles.FooterSp__globalNavigation}>
            <p className={styles.FooterSp__globalNavigationText}>
              <Link to="/">TOP</Link>
            </p>
            <div className={styles.FooterSp__globalNavigationItem}>
              <button
                className={`${styles.FooterSp__globalNavigationToggleButton} ${styles.FooterSp__globalNavigationToggleButton__sectionName}`}
                onClick={() => {
                  setIsDisplayedCompany(!isDisplayedCompany);
                }}
              >
                <span>Company</span>
                <span
                  className={styles.FooterSp__globalNavigationItemToggleIcon}
                >
                  {isDisplayedCompany ? (
                    <Minus size={14} color="#777777" />
                  ) : (
                    <Plus size={14} color="#777777" />
                  )}
                </span>
              </button>
              {isDisplayedCompany ? (
                <div>
                  <ul className={styles.FooterSp__globalNavigationLinks}>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <Link to="/company/overview">Company Overview</Link>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <Link to="/company/ceo-message">CEO Message</Link>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <Link to="/company/board-member">Board Member</Link>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <Link to="/company/vision">Vision</Link>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <Link to="/company/culture">Culture</Link>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <Link to="/company/history">History</Link>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
            {/* Services */}
            <div className={styles.FooterSp__globalNavigationItem}>
              <button
                className={`${styles.FooterSp__globalNavigationToggleButton} ${styles.FooterSp__globalNavigationToggleButton__sectionName}`}
                onClick={() => {
                  setIsDisplayedService(!isDisplayedService);
                }}
              >
                <span>Services</span>
                <span
                  className={styles.FooterSp__globalNavigationItemToggleIcon}
                >
                  {isDisplayedService ? (
                    <Minus size={14} color="#777777" />
                  ) : (
                    <Plus size={14} color="#777777" />
                  )}
                </span>
              </button>
              {isDisplayedService ? (
                <div>
                  <ul className={styles.FooterSp__globalNavigationLinks}>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://elumild.com/">elumild</a>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://evand.jp">Evand</a>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://asfine.co.jp/">AsFine</a>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://suprieve-consulting.com/">
                        Suprieve Consulting
                      </a>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://regenjapan.com/">Regen Japan</a>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://artill.jp/">Artill</a>
                    </li>
                    <li
                      className={`${styles.FooterSp__globalNavigationLink} ${styles.FooterSp__globalNavigationLink__prepared}`}
                    >
                      <span>GFI</span>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://suprieve.com/services/ad/">広告</a>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://tokyolucci.jp/">東京ルッチ</a>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://osakalucci.jp/">大阪ルッチ</a>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://nontraining.jp/">ノントレ</a>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://life-makepersonal.com/">
                        LIFE-MAKE PERSONAL
                      </a>
                    </li>
                    <li className={styles.FooterSp__globalNavigationLink}>
                      <a href="https://grandjoy.jp/">グランジョイクリニック</a>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>

            <div className={styles.FooterSp__globalNavigationItem}>
              <p className={styles.FooterSp__globalNavigationText}>
                <a href={getNewsUrl(host)}>News</a>
              </p>
            </div>
            <div className={styles.FooterSp__globalNavigationItem}>
              <button
                className={`${styles.FooterSp__globalNavigationToggleButton} ${styles.FooterSp__globalNavigationToggleButton__sectionName}`}
                onClick={() => {
                  setIsDisplayedCareer(!isDisplayedCareer);
                }}
              >
                <span>Careers</span>
                <span
                  className={styles.FooterSp__globalNavigationItemToggleIcon}
                >
                  {isDisplayedCareer ? (
                    <Minus size={14} color="#777777" />
                  ) : (
                    <Plus size={14} color="#777777" />
                  )}
                </span>
              </button>
              {isDisplayedCareer ? (
                <div>
                  <button
                    className={styles.FooterSp__globalNavigationToggleButton}
                    onClick={() => {
                      setIsDisplayedCareerNew(!isDisplayedCareerNew);
                    }}
                  >
                    <span>新卒採用（総合職）</span>
                    <span
                      className={
                        styles.FooterSp__globalNavigationItemToggleIcon
                      }
                    >
                      {isDisplayedCareerNew ? (
                        <Minus size={14} color="#777777" />
                      ) : (
                        <Plus size={14} color="#777777" />
                      )}
                    </span>
                  </button>
                  {isDisplayedCareerNew ? (
                    <ul
                      className={styles.FooterSp__globalNavigationToggleItems}
                    >
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/new">TOP</Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/new/why-suprieve">
                          Why Suprieve?
                        </Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/new/about-us">About Us</Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/new/suprieve-now">
                          Suprieve NOW!
                        </Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/new/opportunities">採用情報</Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/new/internship">Internship</Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/new/entry">新卒採用Entry</Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  ) : null}
                  <a
                    href="https://evand.jp/recruit-top/"
                    target="_blank"
                    className={styles.FooterSp__globalNavigationToggleButton}
                  >
                    <span>中途採用</span>
                  </a>
                  <button
                    className={`${styles.FooterSp__globalNavigationToggleButton} ${styles.FooterSp__prepared}`}
                    onClick={() => {
                      // TODO: 準備中が取れたら復活
                      // setIsDisplayedCareerMid(!isDisplayedCareerMid);
                      setIsDisplayedCareerMid(false);
                    }}
                  >
                    <span>キャリア採用（総合・専門職）</span>
                    {/* TODO: 準備中が取れたら復活 */}
                    {/* <span
                  className={
                    isDisplayedCareerMid
                      ? styles.FooterSp__globalNavigationToggleButtonIconMinus
                      : styles.FooterSp__globalNavigationToggleButtonIconPlus
                  }
                /> */}
                  </button>
                  {isDisplayedCareerMid ? (
                    <ul
                      className={styles.FooterSp__globalNavigationToggleItems}
                    >
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/mid">TOP</Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/mid/why-suprieve">
                          Why Suprieve?
                        </Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/mid/about-us">About Us</Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/mid/suprieve-now">
                          Suprieve NOW!
                        </Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/mid/opportunities">採用情報</Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/careers/mid/entry">キャリア採用Entry</Link>
                      </li>
                      <li
                        className={styles.FooterSp__globalNavigationToggleItem}
                      >
                        <Link to="/contact?type=careers">Contact</Link>
                      </li>
                    </ul>
                  ) : null}
                  <Link
                    to="/careers/diversity"
                    target="_blank"
                    className={styles.FooterSp__globalNavigationToggleButton}
                  >
                    <span>障がい者採用</span>
                  </Link>
                </div>
              ) : null}
            </div>
            <div className={styles.FooterSp__globalNavigationItem}>
              <p className={styles.FooterSp__globalNavigationText}>
                <Link to="/contact">Contact</Link>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.FooterSp__columnLower}>
          <div className={styles.FooterSp__terms}>
            <p className={styles.FooterSp__termsText}>
              <a href="/privacy">Privacy Policy</a>
            </p>
          </div>
          <p className={styles.FooterSp__copyright}>
            Copyright © Suprieve Holdings Inc. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
