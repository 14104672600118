import React from "react";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from "./TopPopupMenu.module.scss";

export const TopMenuCompany = () => <div className={styles.company}>
    <div className={styles.menuCompany}>
        <h2>Company</h2>
        <ul>
            <li><Link to="/company/overview">Company Overview<span>会社概要</span></Link></li>
            <li><Link to="/company/ceo-message">CEO Message<span>代表メッセージ</span></Link></li>
            <li><Link to="/company/board-member">Board Member<span>役員紹介</span></Link></li>
            <li><Link to="/company/history">History<span>Suprieveの歴史</span></Link></li>
        </ul>
    </div>
    <div className={styles.companyMenuWrap}>
        <div className={styles.menuCulture}>
            <Link to="/company/culture">
                <StaticImage className={styles.CultureImg} src="../../../images/culture/sbc0416.jpg" alt="" width={200} aspectRatio={10/7}/>
                <h4>Culture</h4>
                <h5>Suprieveの文化</h5>
            </Link>
        </div>
        <div className={styles.menuVision}>
            <Link to="/company/vision">
                <StaticImage className={styles.VisionImg} src="../../../images/vision/vision-osaka.jpg" alt="" width={200} aspectRatio={10/7}/>
                <h4>Vision</h4>
                <h5>ビジョン</h5>
            </Link>
        </div>
    </div>
</div>;