import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import * as styles from "./menus.module.scss";
import { Header } from "../core/Header";
import { WindowSizeContext } from "../../atoms/core/WindowSizeProvidor";
import { md } from "../../atoms/core/media-queries";

const links: ILink[] = [
  {
    id: "top",
    label: "Top",
    href: "/careers/new",
  },
  {
    id: "why-suprieve",
    label: "Why Suprieve?",
    href: "/careers/new/why-suprieve",
  },
  {
    id: "about-us",
    label: "About Us",
    href: "/careers/new/about-us",
  },
  {
    id: "suprieve-now",
    label: "Suprieve NOW!",
    href: "/careers/new/suprieve-now",
  },
  {
    id: "opportunities",
    label: "採用情報",
    href: "/careers/new/opportunities/",
  },
  {
    id: "internship",
    label: "Internship",
    href: "/careers/new/internship",
  },
  {
    id: "entry",
    label: "新卒採用Entry",
    href: "/careers/new/entry",
  },
  {
    id: "contact",
    label: "Contact",
    href: "/contact?type=careers",
  },
];

interface RecruitNewbiesHeaderProps {
  extraHeader?: ReactNode;
  current: string;
  isSp?: boolean;
  isCareerInterview?: boolean;
}

export const RecruitNewbiesHeader = ({
  extraHeader,
  current,
  isSp = false,
  isCareerInterview = false,
}: RecruitNewbiesHeaderProps) => {
  const { width: windowWidth } = useContext(WindowSizeContext);
  return (
    <>
      <Header isSp={isSp} isCareer={true} isCareerInterview={isCareerInterview}>
        <menu className={styles.menuBackground}>
          <label className={styles.newTitle}>新卒採用</label>
          <div className="flex-1" />
          <ul className={styles.menuList}>
            {links.map(({ href, label, id }, i) => (
              <li
                key={`recruit-new-menu-${i}`}
                className={id === current ? styles.selected : ""}
              >
                <Link to={id === current ? "" : href}>{label}</Link>
              </li>
            ))}
          </ul>
        </menu>
        {extraHeader}
      </Header>
      {/* {!isSp && isCareerInterview ? (
        <div className={styles.interviewNavigation}>{extraHeader}</div>
      ) : null} */}
    </>
  );
};
