export type locationInfoProps = {
  path: string;
  pathName: string;
  location: string;
};

export function getLocationTreeInfo(
  href: string,
  protocol: string,
  host: string
): locationInfoProps[] {
  const locationTree: locationInfoProps[] = [
    // TOPは絶対に入るので初期化時に入れておく
    {
      path: `${protocol}//${host}`,
      pathName: "top",
      location: "TOP",
    },
  ];
  // アンカーは外して評価する
  const pathNameArray = href.split("#")[0].split("/");

  pathNameArray.map(pathName => {
    const location = mapPathNameToLocation(pathName);
    if (location) {
      locationTree.push(location);
    }
  });

  return locationTree;
}

// TODO: パンくずをリンク化するためにパスを生成する
function mapPathNameToLocation(
  pathName: string
): locationInfoProps | undefined {
  let locationInfo: locationInfoProps | undefined;
  locationInfo = locationList.find(location => location.pathName === pathName);

  return locationInfo;
}

const locationList = [
  // Company
  {
    path: "/company/overview",
    pathName: "overview",
    location: "Overview",
  },
  {
    path: "/company/ceo-message",
    pathName: "ceo-message",
    location: "CEO Message",
  },
  {
    path: "/company/board-member",
    pathName: "board-member",
    location: "Board Member",
  },
  {
    path: "/company/vision",
    pathName: "vision",
    location: "Vision",
  },
  {
    path: "/company/culture",
    pathName: "culture",
    location: "Culture",
  },
  {
    path: "/company/history",
    pathName: "history",
    location: "History",
  },
  // Careers
  // 新卒採用
  {
    path: "/careers/new",
    pathName: "new",
    location: "新卒採用",
  },
  {
    path: "/careers/new/why-suprieve",
    pathName: "why-suprieve",
    location: "Why Suprieve",
  },
  {
    path: "/careers/new/about-us",
    pathName: "about-us",
    location: "About Suprieve",
  },
  {
    path: "/careers/new/suprieve-now",
    pathName: "suprieve-now",
    location: "Suprieve NOW!",
  },
  {
    path: "/careers/new/opportunities",
    pathName: "opportunities",
    location: "採用情報",
  },
  {
    path: "/careers/new/internship",
    pathName: "internship",
    location: "Internship",
  },
  {
    path: "/careers/new/entry",
    pathName: "entry",
    location: "採用Entry",
  },
  // Suprieve-now インタビュー以下
  {
    path: "/careers/new/suprieve-now/1",
    pathName: "1",
    location: "Interview 1",
  },
  {
    path: "/careers/new/suprieve-now/2",
    pathName: "2",
    location: "Interview 2",
  },
  {
    path: "/careers/new/suprieve-now/3",
    pathName: "3",
    location: "Interview 3",
  },
  {
    path: "/careers/new/suprieve-now/4",
    pathName: "4",
    location: "Interview 4",
  },
  {
    path: "/careers/new/suprieve-now/5",
    pathName: "5",
    location: "Interview 5",
  },
  // diversity
  {
    path: "/careers/diversity",
    pathName: "diversity",
    location: "障がい者採用",
  },
  // Contact
  {
    path: "",
    pathName: "contact",
    location: "Contact",
  },
];
