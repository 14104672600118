// extracted by mini-css-extract-plugin
export var cover = "y_dS";
export var graduate = "y_hF";
export var cultureContainer = "y_kZ";
export var quotationStart = "y_k0";
export var quotationEnd = "y_k1";
export var culture = "y_k2";
export var cultureImg = "y_k3";
export var descriptionOne = "y_k4";
export var industry = "y_k5";
export var industryTitle = "y_k6";
export var consultingTitle = "y_k7";
export var industryImg = "y_k8";
export var elumildImg = "y_k9";
export var consultingImg = "y_lb";
export var occupation = "y_lc";
export var occupationTitle = "y_ld";
export var imageColumn = "y_lf";
export var carouselImgLeft = "y_lg";
export var carouselImgRight = "y_lh";
export var careerpath = "y_lj";
export var careerpathImg = "y_lk";
export var coversection = "y_ll";
export var workingEnvironmentTitle = "y_lm";
export var workingContainer = "y_ln";
export var workingContainerBox = "y_lp";
export var career = "y_lq";
export var careerLeft = "y_lr";
export var careerRight = "y_ls";
export var careerImg = "y_lt";
export var callenge = "y_lv";
export var callengeLeft = "y_lw";
export var callengeImg = "y_lx";
export var callengeImgTwo = "y_ly";
export var callengeRight = "y_lz";
export var employeeBenefits = "y_lB";
export var innerSection = "y_kX";
export var innerColumnLeft = "y_lC";
export var innerColumnRight = "y_lD";
export var whiteColumn = "y_lF";
export var carouselImgBottom = "y_lG";
export var Workplace = "y_lH";
export var backgroundcolor = "y_lJ";
export var workPlaceBoxWrapper = "y_lK";
export var workPlaceBox = "y_lL";
export var tokyoHead = "y_lM";
export var workPlaceBox__tokyo = "y_lN";
export var workPlaceBox__fukuoka = "y_lP";
export var workPlaceBox__nagoya = "y_lQ";
export var workPlaceBox__sapporo = "y_lR";
export var workPlaceBoxButton = "y_lS";
export var detailButton = "y_lT";
export var detailButtonText = "y_lV";
export var arrowIcon = "y_lW";
export var fukuokaHead = "y_lX";
export var fukuokaMargin = "y_lY";
export var nagoyaHead = "y_lZ";
export var carousel = "y_l0";
export var carousel2 = "y_l1";
export var SapporoHead = "y_l2";