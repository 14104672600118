import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { ENTERED, EXITING, EXITED } from "react-transition-group/Transition";
import { CloseGraphics } from "../../atoms/graphics/CloseGraphics";
import * as styles from "../menus/menus.module.scss";
import { Link } from "gatsby";
import serviceLinks from "../home/data/all/service";
import { CustomLink } from "../../atoms/link/CustomLink";
import Splogo from "../../images/logo_black.png";
import classNames from "classnames";

const DEFAULT_ANIMATION_DURATION = 250;

const ANIMATION_CLASS_NAMES = {
  ENTERED: styles.spDrawer__itemListSingle__entered,
  EXITING: styles.spDrawer__itemListSingle__exiting,
} as const;

const ANIMATION_ITEM_IN_ITEM_CLASS_NAMES = {
  ENTERED: styles.spDrawer__itemList__entered,
  EXITING: styles.spDrawer__itemList__exiting,
} as const;

const ANIMATION_ITEM_IN_ITEM_LIST_CLASS_NAMES = {
  ENTERED: styles.spDrawer__itemInItemList__entered,
  EXITING: styles.spDrawer__itemInItemList__exiting,
} as const;

function getModifierClassNames(
  state: string,
  isItemInItem?: boolean,
  isItemInItemList?: boolean
) {
  if (isItemInItem) {
    if (isItemInItemList) {
      switch (state) {
        case ENTERED:
          return ANIMATION_ITEM_IN_ITEM_LIST_CLASS_NAMES.ENTERED;
        case EXITING:
          return ANIMATION_ITEM_IN_ITEM_LIST_CLASS_NAMES.EXITING;
      }
    } else {
      switch (state) {
        case ENTERED:
          return ANIMATION_ITEM_IN_ITEM_CLASS_NAMES.ENTERED;
        case EXITING:
          return ANIMATION_ITEM_IN_ITEM_CLASS_NAMES.EXITING;
      }
    }
  } else {
    switch (state) {
      case ENTERED:
        return ANIMATION_CLASS_NAMES.ENTERED;
      case EXITING:
        return ANIMATION_CLASS_NAMES.EXITING;
    }
  }
}

function getNewsUrl(host: string): string {
  const isStg = host.includes("staging");
  const isLocal = host.includes("localhost");

  return isStg || isLocal
    ? "https://staging.suprieve.com/blog/"
    : "https://suprieve.com/blog/";
}

export const SpMenu = ({ open, onClose }) => {
  if (typeof window === "undefined") {
    return null;
  }

  const host = window.location.host;

  const [isOpenCompany, setIsOpenCompany] = useState(false);
  const [isOpenServices, setIsOpenServices] = useState(false);
  const [isOpenCareers, setIsOpenCareers] = useState(false);
  const [isOpenCareersNew, setIsOpenCareersNew] = useState(false);

  return (
    <div className={classNames(styles.spDrawer, open ? styles.open : "")}>
      <div className="flex">
        <Link to="/">
          <img className={styles.Splogo} src={Splogo} alt="Suprieve Holdings" />
        </Link>
        <div className="flex-1" />
        <button onClick={e => onClose()} className={styles.spClose}>
          <CloseGraphics />
        </button>
      </div>
      <div className={styles.spMenuText}>
        {/* Company */}
        <div
          className={styles.spDrawer__item}
          style={isOpenCompany ? { height: "236px" } : undefined}
        >
          <button
            className={styles.spDrawer__itemButton}
            onClick={() => {
              setIsOpenCompany(!isOpenCompany);
            }}
          >
            <p>Company</p>

            <div className={styles.spDrawer__icon}>
              <div
                className={classNames(
                  styles.spDrawer__borderVertical,
                  isOpenCompany ? styles.spDrawer__borderVertical__hidden : ""
                )}
              />
              <div
                className={classNames(
                  styles.spDrawer__borderHorizontal,
                  isOpenCompany
                    ? styles.spDrawer__borderHorizontal__rotated
                    : ""
                )}
              />
            </div>
          </button>
          <CSSTransition
            in={isOpenCompany}
            timeout={DEFAULT_ANIMATION_DURATION}
            unmountOnExit={true}
            // react-transition-groupのデフォルトclassNameを出力しないようにする
            classNames={{}}
          >
            {state => (
              <div
                className={classNames(
                  styles.spDrawer__itemListSingle,
                  getModifierClassNames(state)
                )}
              >
                <li>
                  <CustomLink to="/company/overview">
                    Company Overview
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to="/company/ceo-message">CEO Message</CustomLink>
                </li>
                <li>
                  <CustomLink to="/company/board-member">
                    Board member
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to="/company/vision">Vision</CustomLink>
                </li>
                <li>
                  <CustomLink to="/company/culture">Culture</CustomLink>
                </li>
                <li>
                  <CustomLink to="/company/history">History</CustomLink>
                </li>
              </div>
            )}
          </CSSTransition>
        </div>

        {/* Services */}
        <div
          className={styles.spDrawer__item}
          style={isOpenServices ? { height: "268px" } : undefined}
        >
          <button
            className={styles.spDrawer__itemButton}
            onClick={() => {
              setIsOpenServices(!isOpenServices);
            }}
          >
            <p>Services</p>
            <div className={styles.spDrawer__icon}>
              <div
                className={classNames(
                  styles.spDrawer__borderVertical,
                  isOpenServices ? styles.spDrawer__borderVertical__hidden : ""
                )}
              />
              <div
                className={classNames(
                  styles.spDrawer__borderHorizontal,
                  isOpenServices
                    ? styles.spDrawer__borderHorizontal__rotated
                    : ""
                )}
              />
            </div>
          </button>
          <CSSTransition
            in={isOpenServices}
            timeout={DEFAULT_ANIMATION_DURATION}
            unmountOnExit={true}
            // react-transition-groupのデフォルトclassNameを出力しないようにする
            classNames={{}}
          >
            {state => (
              <div
                className={classNames(
                  styles.spDrawer__itemListSingle,
                  getModifierClassNames(state)
                )}
              >
                <li>
                  <CustomLink to={serviceLinks.elumild}>elumild</CustomLink>
                </li>
                <li>
                  <CustomLink to={serviceLinks.evand}>Evand</CustomLink>
                </li>
                <li>
                  <CustomLink to={serviceLinks.asfine}>Asfine</CustomLink>
                </li>
                <li>
                  <CustomLink to={serviceLinks.sc}>
                    Suprieve Consulting
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={serviceLinks.rj}>Regen Japan</CustomLink>
                </li>
                <li>
                  <CustomLink to={serviceLinks.artill}>Artill</CustomLink>
                </li>
                <li className={styles.prepared}>GFI</li>
              </div>
            )}
          </CSSTransition>
        </div>

        {/* News */}
        <h2>
          <CustomLink to={getNewsUrl(host)}>News</CustomLink>
        </h2>

        {/* Careers */}
        <div
          className={styles.spDrawer__item}
          style={
            isOpenCareers
              ? { height: isOpenCareersNew ? "428px" : "160px" }
              : undefined
          }
        >
          <button
            className={styles.spDrawer__itemButton}
            onClick={() => {
              if (isOpenCareers) {
                setIsOpenCareersNew(false);
              }

              setIsOpenCareers(!isOpenCareers);
            }}
          >
            <p>Careers</p>
            <div className={styles.spDrawer__icon}>
              <div
                className={classNames(
                  styles.spDrawer__borderVertical,
                  isOpenCareers ? styles.spDrawer__borderVertical__hidden : ""
                )}
              />
              <div
                className={classNames(
                  styles.spDrawer__borderHorizontal,
                  isOpenCareers
                    ? styles.spDrawer__borderHorizontal__rotated
                    : ""
                )}
              />
            </div>
          </button>

          <CSSTransition
            in={isOpenCareers}
            timeout={DEFAULT_ANIMATION_DURATION}
            unmountOnExit={true}
            // react-transition-groupのデフォルトclassNameを出力しないようにする
            classNames={{}}
          >
            {state => (
              <div
                className={classNames(
                  styles.spDrawer__itemList,
                  getModifierClassNames(state, true)
                )}
              >
                <div
                  className={styles.spDrawer__itemInItem}
                  style={isOpenCareersNew ? { height: "280px" } : undefined}
                >
                  <button
                    className={styles.spDrawer__itemInItemButton}
                    onClick={() => {
                      setIsOpenCareersNew(!isOpenCareersNew);
                    }}
                  >
                    <p>新卒採用（総合職）</p>
                    <div className={styles.spDrawer__icon}>
                      <div
                        className={classNames(
                          styles.spDrawer__borderVertical,
                          isOpenCareersNew
                            ? styles.spDrawer__borderVertical__hidden
                            : ""
                        )}
                      />
                      <div
                        className={classNames(
                          styles.spDrawer__borderHorizontal,
                          isOpenCareersNew
                            ? styles.spDrawer__borderHorizontal__rotated
                            : ""
                        )}
                      />
                    </div>
                  </button>
                  <CSSTransition
                    in={isOpenCareersNew}
                    timeout={DEFAULT_ANIMATION_DURATION}
                    unmountOnExit={true}
                    // react-transition-groupのデフォルトclassNameを出力しないようにする
                    classNames={{}}
                  >
                    {state => (
                      <div
                        className={classNames(
                          styles.spDrawer__itemInItemList,
                          getModifierClassNames(state, true, true)
                        )}
                      >
                        <li className={styles.spDrawer__itemInItemListText}>
                          <CustomLink to="/careers/new">新卒採用TOP</CustomLink>
                        </li>
                        <li className={styles.spDrawer__itemInItemListText}>
                          <CustomLink to="/careers/new/why-suprieve">
                            Why Suprieve
                          </CustomLink>
                        </li>
                        <li className={styles.spDrawer__itemInItemListText}>
                          <CustomLink to="/careers/new/about-us">
                            About Us
                          </CustomLink>
                        </li>
                        <li className={styles.spDrawer__itemInItemListText}>
                          <CustomLink to="/careers/new/suprieve-now">
                            Suprieve NOW!
                          </CustomLink>
                        </li>
                        <li className={styles.spDrawer__itemInItemListText}>
                          <CustomLink to="/careers/new/opportunities/">
                            採用情報
                          </CustomLink>
                        </li>
                        <li className={styles.spDrawer__itemInItemListText}>
                          <CustomLink to="/careers/new/internship">
                            Internship
                          </CustomLink>
                        </li>
                        <li className={styles.spDrawer__itemInItemListText}>
                          <CustomLink to="/careers/new/entry">
                            新卒採用Entry
                          </CustomLink>
                        </li>
                        <li className={styles.spDrawer__itemInItemListText}>
                          <CustomLink to="/contact?type=careers">
                            Contact
                          </CustomLink>
                        </li>
                      </div>
                    )}
                  </CSSTransition>
                </div>

                <h3 className={styles.spDrawer__careerTitle}>
                  <CustomLink to={serviceLinks.evand}>中途採用</CustomLink>
                </h3>
                <div className={styles.spDrawer__careerTitle}>
                  <p className={styles.preparedTitle}>
                    キャリア採用（総合・専門職）
                  </p>
                  {/* <details>
            <summary>キャリア採用（総合・専門職）</summary>
            <div className={styles.thridBox}>
                <li>
                  <CustomLink to="/careers/mid">キャリア採用TOP</CustomLink>
                </li>
                <li>
                  <CustomLink to="/careers/mid/why-suprieve">
                    Why Suprieve
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to="/careers/mid/about-us">About Us</CustomLink>
                </li>
                <li>
                  <CustomLink to="/careers/mid/suprieve-now">
                    Suprieve NOW!
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to="/careers/mid/opportunities/">
                    採用情報
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to="/careers/mid/entry">
                    キャリア採用Entry
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to="/contact?type=careers">Contact</CustomLink>
                </li>
              </div>
            </details> */}
                </div>
                <h3 className={styles.spDrawer__careerTitle}>
                  <CustomLink to="/careers/diversity">障がい者雇用</CustomLink>
                </h3>
              </div>
            )}
          </CSSTransition>
        </div>
        <h2>
          <CustomLink to="/contact">Contact</CustomLink>
        </h2>
      </div>
    </div>
  );
};
