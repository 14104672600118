import React, {
  Children,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import classNames from "classnames";
import * as styles from "./MenuManagement.module.scss";
import { throttle } from "lodash";
import { MAX_WINDOW_HEIGHT } from "../../pages/careers/new";

export const MenuManagement = ({
  children,
  isCareer = false,
  isSp = false,
  isCareerInterview = false,
  isHistory = false,
  isSecondView = false,
}) => {
  const [dir, setDir] = useState(-1);
  const [isScrollable, setIsScrollable] = useState(false);
  const [isDisplayedPageNavigation, setIsDisplayedPageNavigation] =
    useState(false);
  const scrollTopRef = useRef(0);
  const dirRef = useRef(1);
  dirRef.current = dir;

  let standardHeight = MAX_WINDOW_HEIGHT;
  if (typeof window !== "undefined") {
    standardHeight =
      window.innerHeight <= MAX_WINDOW_HEIGHT
        ? window.innerHeight
        : MAX_WINDOW_HEIGHT;
  }

  const [isDownwardScroll, setIsDownwardScroll] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    setIsDownwardScroll(currPos.y < prevPos.y);
  }, []);

  useEffect(() => {
    if (typeof window === "undefined" || children.length === 1) {
      return;
    }
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const scrollHandler = useCallback(
    throttle(() => {
      const standardHeight =
        window.innerHeight <= MAX_WINDOW_HEIGHT
          ? window.innerHeight
          : MAX_WINDOW_HEIGHT;
      if (!isCareer) {
        const d =
          document.documentElement.scrollTop - scrollTopRef.current > 0
            ? 1
            : -1;
        if (dirRef.current != d) {
          setDir(d);
        }
        scrollTopRef.current = document.documentElement.scrollTop;
      } else {
        setIsScrollable(
          document.documentElement.scrollTop >= standardHeight - 180 &&
            document.documentElement.scrollTop < standardHeight - 120
        );

        if (document.documentElement.scrollTop >= standardHeight - 120) {
          setIsDisplayedPageNavigation(true);
        } else {
          setIsDisplayedPageNavigation(false);
        }
        scrollTopRef.current = document.documentElement.scrollTop;
      }
    }, 250),
    []
  );

  const childrenArr = Children.toArray(children).filter(
    child => child !== null
  );

  return (
    <div
      className={classNames(
        styles.MenuManagement,
        isScrollable && childrenArr.length > 1 && !isSp
          ? styles.MenuManagement__scrollable
          : "",
        // career用
        !isSp && isCareer && isDisplayedPageNavigation
          ? styles.MenuManagement__reFixed
          : "",
        // career用
        isSp && isDisplayedPageNavigation && isDownwardScroll
          ? styles.MenuManagement__downwardScroll
          : "",
        // career用
        isSp && isDisplayedPageNavigation && !isDownwardScroll
          ? styles.MenuManagement__upwardScroll
          : "",
        // history用 SPのみ適応だが、追加ヘッダーをhistory.tsx側のscssで制御しているので問題なし
        isHistory && isSecondView ? styles.MenuManagement__downwardScroll : ""
      )}
      style={
        isCareer
          ? {
              height: isCareerInterview ? 180 : 120,
              top:
                isScrollable && !isSp && !isCareerInterview
                  ? standardHeight - 180
                  : 0,
            }
          : { height: isHistory ? 120 : 60 }
      }
    >
      {childrenArr.map((child, i) => (
        <div
          key={`menu-management-${i}`}
          className={classNames(
            i === 0 ? styles.MenuManagement__globalNavigation : "",
            i === 1 && !isHistory
              ? styles.MenuManagement__careerNavigation
              : "",
            i === 1 && isCareerInterview
              ? styles.MenuManagement__careerNavigation_interview
              : "",
            i === 1 && isSp && isDisplayedPageNavigation && isDownwardScroll
              ? styles.MenuManagement__careerNavigation_downwardScroll
              : "",
            i === 1 && isHistory ? styles.MenuManagement__historyNavigation : ""
          )}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
