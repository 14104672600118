// extracted by mini-css-extract-plugin
export var globalBackground = "S_tv";
export var globalBackground__white = "S_tw";
export var globalBackground__blur = "S_tx";
export var globalBackground__whiteBackground = "S_ty";
export var globalTitle = "S_tz";
export var menuListMid = "S_tB";
export var menuListDiversity = "S_tC";
export var globalList = "S_tD";
export var logo = "S_hG";
export var blackFont = "S_tF";
export var logoSvg = "S_tG";
export var menuBackground = "S_tH";
export var menuDiversityInner = "S_tJ";
export var menuDiversitySp = "S_tK";
export var anchorSpMenu = "S_tL";
export var midAnchorSpMenu = "S_tM";
export var anchorSpItem = "S_tN";
export var menuDiversitySpItem = "S_tP";
export var newTitle = "S_tQ";
export var midTitle = "S_tR";
export var diversityTitle = "S_gL";
export var menuList = "S_tS";
export var selected = "S_tT";
export var anchorProvider = "S_tV";
export var interviewBackground = "S_tW";
export var indicator = "S_mr";
export var diversity = "S_tX";
export var interviewList = "S_tY";
export var currentIndicator = "S_tZ";
export var spMenu = "S_t0";
export var humburger = "S_t1";
export var Splogo = "S_t2";
export var spMenuText = "S_t3";
export var spDrawer__item = "S_t4";
export var spDrawer__itemButton = "S_t5";
export var spDrawer__itemListSingle = "S_t6";
export var spDrawer__itemListSingle__entered = "S_t7";
export var spDrawer__itemListSingle__exiting = "S_t8";
export var spDrawer__itemList = "S_t9";
export var spDrawer__itemList__entered = "S_vb";
export var spDrawer__itemList__exiting = "S_vc";
export var spDrawer__itemInItem = "S_vd";
export var spDrawer__itemInItemButton = "S_vf";
export var spDrawer__itemInItemList = "S_vg";
export var spDrawer__itemInItemList__entered = "S_vh";
export var spDrawer__itemInItemList__exiting = "S_vj";
export var spDrawer__itemInItemListText = "S_vk";
export var spDrawer__careerTitle = "S_vl";
export var button = "S_f";
export var spDrawer = "S_vm";
export var open = "S_l4";
export var spDrawer__icon = "S_vn";
export var spDrawer__borderVertical = "S_vp";
export var spDrawer__borderVertical__hidden = "S_vq";
export var spDrawer__borderHorizontal = "S_vr";
export var spDrawer__borderHorizontal__rotated = "S_vs";
export var spClose = "S_vt";
export var preparedTitle = "S_vv";
export var prepared = "S_vw";