import React from "react";

export const Minus = ({ size = 24, color = "#000000" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 14 1"
  >
    <path
      id="パス_1667"
      data-name="パス 1667"
      d="M12312.413,2771.5h14"
      transform="translate(-12312.413 -2771)"
      fill="none"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);
