const serviceLinks = {
  elumild: "https://elumild.com/",
  evand: "https://evand.jp/",
  sc: "https://suprieve-consulting.com/",
  rj: "https://regenjapan.com/",
  media: "https://tokyolucci.jp/",
  artill: "https://artill.jp/",
  ad: "/services/ad",
  asfine: "https://asfine.co.jp/",
  husty: "https://husty.jp/",
  unique_on: "http://unique-on.jp/",
  and_one: "https://company.andonestore.jp/",
  codomo: "https://www.codomojab.com/",
  education: "/",
  zenh: "https://zenhlife.com/",
};

export default serviceLinks;
