import React from "react";
import { Title, Meta } from "react-head";
import {SERV_HOST} from "../constans";

interface SEOProps {
    pathname: string;
    title: string;
    description: string;
    image: string;
}
export const SEO = ({pathname, title, description, image}:SEOProps) => {
    title = `Suprieve Holdings株式会社 | ${title}`;
    return <>
        <Title>{title}</Title>
        <Meta name="description" content={description}/>
        <Meta name="image" content={image}/>

        {/* OpenGraph tags */}
        <Meta property="og:url" content={`${SERV_HOST}${pathname}`}/>
        <Meta property="og:title" content={title}/>
        <Meta property="og:description" content={description}/>
        <Meta property="og:image" content={image}/>
        <Meta property="og:type" content="website" />
        <Meta property="og:site_name" content="Suprieve Holdings株式会社" />
        {/*<Meta property="fb:app_id" content={seo.social.fbAppID} />*/}

        {/* Twitter Card tags */}
        <Meta name="twitter:card" content="summary"/>
        <Meta name="twitter:creator" content="@Suprieve8989"/>
        <Meta name="twitter:title" content={title}/>
        <Meta name="twitter:description" content={description}/>
        <Meta name="twitter:image" content={image}/>
    </>;
}