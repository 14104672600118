import React from "react";
import {Link} from "gatsby";

export const CustomLink = ({to, children, className='', onClick = (e:any) => {}, ...rest}) => <>
    {
        to.indexOf('http') === 0 &&
        <a className={className} href={to} target="_blank" onClick={onClick} {...rest}>{children}</a>
    }
    {
        to.indexOf('http') !== 0 &&
        <Link className={className} to={to} onClick={onClick} {...rest}>{children}</Link>
    }
</>
