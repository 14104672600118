import React, { ReactNode, useEffect, useState } from "react";
import { GlobalHeader } from "./GlobalHeader";
import { MenuManagement } from "../../atoms/menu/MenuManagement";
import { TopPopupMenu } from "../menus/top/TopPopupMenu";
import { SpMenu } from "./SpMenu";

interface HeaderProps {
  children?: ReactNode;
  isWhite?: boolean;
  isCareer?: boolean;
  isSp?: boolean;
  isCareerInterview?: boolean;
  isHistory?: boolean;
  isWhiteBackground?: boolean;
}
let timeoutHandler;
let unmount = false;
export const Header = ({
  children,
  isWhite = false,
  isCareer = false,
  isSp = false,
  isCareerInterview = false,
  isHistory = false,
  isWhiteBackground = false,
}: HeaderProps) => {
  const [popup, setPopup] = useState("");
  const [popupRect, setPopupRect] = useState({ top: 0, left: 0 });
  const [spMenuOpen, setSpMenuOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const onMouseOver = (e, page) => {
    clearTimeout(timeoutHandler);
    if (unmount) {
      return;
    }
    requestAnimationFrame(() => {
      if (unmount) {
        return;
      }
      if (e?.currentTarget) {
        setPopupRect(e.currentTarget.getBoundingClientRect());
      }
      setPopup(page);
      setShowPopup(true);
    });
  };
  const onMouseOutGenerator = n => () => {
    clearTimeout(timeoutHandler);
    timeoutHandler = setTimeout(hidePopup, n);
  };
  const hidePopup = () => {
    if (unmount) {
      return;
    }
    setShowPopup(false);
    clearTimeout(timeoutHandler);
    timeoutHandler = setTimeout(() => setPopup(""), 1200);
  };
  const onMenuMouseOver = () => {
    clearTimeout(timeoutHandler);
  };
  useEffect(() => {
    unmount = false;
    return () => {
      unmount = true;
    };
  }, []);
  return (
    <header>
      {spMenuOpen ? null : (
        <MenuManagement
          isSp={isSp}
          isCareer={isCareer}
          isCareerInterview={isCareerInterview}
          isHistory={isHistory}
          isSecondView={isWhiteBackground}
        >
          {!(typeof window === "undefined") && (
            <React.Suspense fallback={<div />}>
              <GlobalHeader
                toggleSpMenu={e => setSpMenuOpen(!spMenuOpen)}
                onMenuMouseOver={onMouseOver}
                onMenuMouseOut={onMouseOutGenerator(100)}
                isWhite={isWhite}
                isCareerPage={isCareer}
                isHistoryPage={isHistory}
                isWhiteBackground={isWhiteBackground}
              />
            </React.Suspense>
          )}
          {children}
        </MenuManagement>
      )}
      <div className="sp-only">
        <SpMenu open={spMenuOpen} onClose={setSpMenuOpen.bind(null, false)} />
      </div>
      <div className="pc-only">
        {!(typeof window === "undefined") && (
          <TopPopupMenu
            page={popup}
            top={popupRect.top + 80}
            right={24}
            dismiss={hidePopup}
            onMouseOut={onMouseOutGenerator(100)}
            onMouseOver={onMenuMouseOver}
            show={showPopup}
          />
        )}
      </div>
    </header>
  );
};
