// extracted by mini-css-extract-plugin
export var FooterSp = "T_vx";
export var FooterSp__columnUpper = "T_vy";
export var FooterSp__pageTopNavigation = "T_vz";
export var FooterSp__verticalLine = "T_vB";
export var FooterSp__pageTopNavigationText = "T_vC";
export var FooterSp__companyLogo = "T_vD";
export var FooterSp__breadCrumb = "T_vF";
export var FooterSp__breadCrumbItem = "T_vG";
export var FooterSp__breadCrumbItemTriangle = "T_vH";
export var FooterSp__breadCrumbItemText_current = "T_vJ";
export var FooterSp__breadCrumbItemText = "T_vK";
export var FooterSp__columnMiddle = "T_vL";
export var FooterSp__snsNavigationTitle = "T_vM";
export var FooterSp__snsNavigationButtons = "T_vN";
export var FooterSp__snsNavigationButton = "T_vP";
export var FooterSp__snsNavigationButtonLink = "T_vQ";
export var FooterSp__snsNavigation = "T_vR";
export var FooterSp__globalNavigation = "T_vS";
export var FooterSp__globalNavigationText = "T_vT";
export var FooterSp__globalNavigationItem = "T_vV";
export var FooterSp__globalNavigationLinks = "T_vW";
export var FooterSp__globalNavigationLink__prepared = "T_vX";
export var FooterSp__globalNavigationLink = "T_vY";
export var FooterSp__globalNavigationToggleButton = "T_vZ";
export var FooterSp__globalNavigationToggleButton__sectionName = "T_v0";
export var FooterSp__globalNavigationItemToggleIcon = "T_v1";
export var FooterSp__globalNavigationToggleItems = "T_v2";
export var FooterSp__globalNavigationToggleItem = "T_v3";
export var FooterSp__columnLower = "T_v4";
export var FooterSp__terms = "T_v5";
export var FooterSp__termsText = "T_v6";
export var FooterSp__copyright = "T_v7";
export var FooterSp__prepared = "T_v8";