import {gql} from "apollo-boost";


const BASE_QUERY = `
pageInfo {
    endCursor
    startCursor
}
edges {
    node {
        author {
            node {
                name
            }
        }
        date
        excerpt
        title
        slug
        categories {
            nodes {
                name
                slug
            }
        }
        tags {
          nodes {
            name
            slug
          }
        }
        featuredImage {
            node {
                sizes(size: POST_THUMBNAIL)
                srcSet(size: POST_THUMBNAIL)
                sourceUrl(size: POST_THUMBNAIL)
            }
        }
    }
    cursor
}`;

export const NEWS_TOP = gql`
    query {
        categories {
            nodes {
                name
                slug
            }
        }
        posts {
            ${BASE_QUERY}
        }
    }
`;

export const POSTS_BY_QUERY = gql`
    query SearchQuery($query: String!) {
        posts(where: {search: $query}) {
            ${BASE_QUERY}
        }
    }
`;

export const POSTS_BY_CATEGORY = gql`
    query PostsByCategory($category: String!) {
        posts(where: {categoryName: $category}) {
            ${BASE_QUERY}
        }
    }
`;

export const POSTS_BY_TAG = gql`
    query PostsByCategory($tag: String!) {
        posts(where: {tag: $tag}) {
            ${BASE_QUERY}
        }
    }
`;

export const POSTS_WITH_LIMIT = gql`
    query PostsWithLimit($limit: Int!) {
        posts(first: $limit) {
            ${BASE_QUERY}
        }
    }
`;