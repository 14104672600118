import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./TopPopupMenu.module.scss";
import { CustomLink } from "../../../atoms/link/CustomLink";

export const TopMenuCareer = () => (
  <div className={`flex`}>
    <div className={styles.flexColumn}>
      <div className={styles.careerHead}>
        <h2>Careers</h2>
      </div>
      <div className={styles.row}>
        <div className={styles.careerMenuWrap}>
          <div className={styles.menuCareer}>
            <CustomLink to="/careers/new">
              <StaticImage
                className={styles.careerImg}
                src="../../../images/head_popup/_kpo0802_newgraduate_head.jpg"
                alt=""
                width={240}
                aspectRatio={3 / 2}
              />
              <h4>
                New Graduate
                <br />
                <span>新卒採用（総合職）</span>
              </h4>
            </CustomLink>
          </div>
          <div className={styles.menuCareer}>
            <CustomLink to="https://evand.jp/recruit-top/">
              <StaticImage
                className={styles.careerImg}
                src="../../../images/head_popup/evand_210228_24.jpg"
                alt=""
                width={240}
                aspectRatio={3 / 2}
              />
              <h4>
                Mid-career&nbsp;(no exp)
                <br />
                <span>中途採用</span>
              </h4>
              <h5>グループ企業の採用サイトへ移動します</h5>
            </CustomLink>
          </div>
        </div>
        <div className={styles.careerMenuWrap}>
          <div className={`${styles.menuCareer} ${styles.menuCareerPrepare}`}>
            <StaticImage
              className={`${styles.careerImg} ${styles.careerImg__prepared}`}
              src="../../../images/head_popup/pc_popover_midcareer_pre.png"
              alt=""
              width={240}
              aspectRatio={3 / 2}
            />
            <h4>
              Mid-career&nbsp;(exp)
              <br />
              <span>キャリア採用&nbsp;(総合・専門職）</span>
            </h4>
          </div>
          <div className={styles.menuCareer}>
            <CustomLink to="/careers/diversity">
              <StaticImage
                className={styles.careerImg}
                src="../../../images/head_popup/AdobeStock_351815619_re.jpg"
                alt=""
                width={240}
                aspectRatio={3 / 2}
              />
              <h4>
                For Disabilities
                <br />
                <span>障がい者採用</span>
              </h4>
            </CustomLink>
          </div>
        </div>
      </div>
    </div>
  </div>
);
