// extracted by mini-css-extract-plugin
export var cover = "s_dS";
export var coverCenter = "s_b";
export var graduate = "s_hF";
export var blog = "s_7";
export var blogContainer = "s_fp";
export var blogContainerLast = "s_fq";
export var blogContainerLeft = "s_fr";
export var blogContainerRight = "s_fs";
export var first = "s_fx";
export var firstText = "s_fy";
export var slide = "s_fz";
export var logo = "s_hG";
export var news = "s_gh";
export var nowNews = "s_gj";
export var topTitle = "s_gk";
export var container = "s_gl";
export var pconly = "s_gm";
export var sponly = "s_gn";
export var smallContainer = "s_gp";
export var icon = "s_t";
export var category = "s_gq";
export var newsArchive = "s_gr";
export var reason = "s_gs";
export var reasonTitle = "s_gt";
export var reasonContainer = "s_gv";
export var reasonImg = "s_gw";
export var reasonContent = "s_gx";
export var reasonContentTwo = "s_hH";
export var reasonContentWrapper = "s_hJ";
export var viewAll = "s_gy";
export var about = "s_gz";
export var aboutTitle = "s_gB";
export var messageContainer = "s_gC";
export var massageContainerLeft = "s_gD";
export var massageContainerRight = "s_gF";
export var messageImg = "s_gG";
export var viewAllGray = "s_gH";
export var view = "s_gJ";
export var link = "s_hK";
export var linkLeft = "s_hL";
export var linkCenter = "s_hM";
export var linkRight = "s_hN";