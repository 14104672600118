import React from "react";

export const InstagramIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 21.998 22.002"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.071"
        y1="0.038"
        x2="0.915"
        y2="0.947"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#656565" />
        <stop offset="1" stopColor="#3c3c3c" stopOpacity="0.467" />
      </linearGradient>
    </defs>
    <path
      d="M11,1.982c2.937,0,3.285.011,4.445.064a6.063,6.063,0,0,1,2.042.379,3.644,3.644,0,0,1,2.088,2.087,6.089,6.089,0,0,1,.379,2.043c.053,1.16.064,1.507.064,4.445s-.011,3.284-.064,4.444a6.089,6.089,0,0,1-.379,2.043,3.641,3.641,0,0,1-2.088,2.087,6.063,6.063,0,0,1-2.042.379c-1.16.053-1.508.064-4.445.064s-3.285-.011-4.445-.064a6.063,6.063,0,0,1-2.042-.379,3.638,3.638,0,0,1-2.088-2.087,6.088,6.088,0,0,1-.378-2.043c-.053-1.16-.064-1.507-.064-4.444s.011-3.285.064-4.445a6.088,6.088,0,0,1,.378-2.043A3.641,3.641,0,0,1,4.512,2.425a6.063,6.063,0,0,1,2.042-.379c1.16-.053,1.508-.064,4.445-.064M11,0C8.012,0,7.637.012,6.464.066a8.1,8.1,0,0,0-2.67.512A5.631,5.631,0,0,0,.577,3.8,8.074,8.074,0,0,0,.066,6.466C.012,7.639,0,8.014,0,11s.012,3.362.066,4.535a8.074,8.074,0,0,0,.511,2.671,5.619,5.619,0,0,0,3.217,3.217,8.1,8.1,0,0,0,2.67.512C7.638,21.989,8.012,22,11,22s3.362-.013,4.535-.066a8.1,8.1,0,0,0,2.67-.512,5.625,5.625,0,0,0,3.217-3.217,8.1,8.1,0,0,0,.511-2.671C21.986,14.363,22,13.988,22,11s-.012-3.362-.066-4.535A8.1,8.1,0,0,0,21.421,3.8,5.631,5.631,0,0,0,18.2.578a8.1,8.1,0,0,0-2.67-.512C14.36.013,13.986,0,11,0Zm0,5.5A5.5,5.5,0,1,0,16.5,11,5.5,5.5,0,0,0,11,5.5Zm0,9.069A3.57,3.57,0,1,1,14.569,11h0A3.571,3.571,0,0,1,11,14.573ZM16.849,3.8a1.357,1.357,0,0,1,0,2.714c-.75,0-1.357-1.357-1.357-1.357S16.1,3.8,16.849,3.8Z"
      fill="url(#linear-gradient)"
    />
  </svg>
);
