import React, { createContext, useCallback, useEffect, useState } from "react";
import { throttle } from "lodash";

const findAnchors = children => {
  React.Children.forEach(children, (child, i) => {
    findAnchors(child.children);
    // console.log('child:', child, i);
  });
};
interface AnchorContextType {
  setAnchors: (value: string[]) => void;
  currentAnchorIndex: number;
}
export const AnchorContext = createContext({} as AnchorContextType);

// thin-wrapper
export const AnchorProvider = ({ children }) => {
  const [anchors, setAnchors] = useState([] as string[]);
  const [currentAnchorIndex, setCurrentAnchorIndex] = useState(-1);
  const onScroll = useCallback(
    throttle(() => {
      if (anchors.length === 0) {
        return;
      }
      let i = 0;
      for (const a of anchors) {
        const el = document.getElementById(a);
        if (el) {
          const top = el.getBoundingClientRect().top;
          if (top > 0) {
            if (top > 200) {
              --i;
            }
            break;
          }
          i++;
        }
      }
      i = Math.max(0, Math.min(anchors.length - 1, i));
      if (i !== currentAnchorIndex) {
        setCurrentAnchorIndex(i);
      }
    }, 100),
    [anchors, currentAnchorIndex]
  );
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    requestAnimationFrame(onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [anchors]);

  const value = {
    setAnchors,
    currentAnchorIndex,
  };
  return (
    <AnchorContext.Provider value={value}>{children}</AnchorContext.Provider>
  );
};
