import { Link } from "gatsby";
import React, {
  createRef,
  CSSProperties,
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import * as styles from "./index.module.scss";
import { cssWithPrefix } from "../../../../utils/prefixer";
import { throttle } from "lodash";
import { MAX_WINDOW_HEIGHT } from "../../../../pages/careers/new";

export const THLESHOLD_WIDTH_SP = 821;

export type PageInnerNavigatorItemProps = {
  anchor: string;
  name: string;
};

type PageInnerNavigatorProps = {
  items: PageInnerNavigatorItemProps[];
  className?: string;
};

const PageInnerNavigator: FunctionComponent<PageInnerNavigatorProps> = ({
  items,
  className = "",
}) => {
  // グローバルナビ + キャリアナビ の高さ
  const PC_CAREER_HEADER_HEIGHT = 120;
  const [isSp, setIsSp] = useState(false);
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const [isTouchHeader, setIsTouchHeader] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [currentAnchorIndex, setCurrentAnchorIndex] = useState(0);
  const [innerHeight, setInnerHeight] = useState(0);
  const refs = useRef<RefObject<HTMLLIElement>[]>([]);
  items.forEach((_, index) => {
    refs.current[index] = createRef<HTMLLIElement>();
  });
  const [pageInnerNavigatorTop, setPageInnerNavigatorTop] = useState(0);

  const scrollHandler = useCallback(
    throttle(() => {
      let thresholdHeight = MAX_WINDOW_HEIGHT;
      if (typeof window !== "undefined") {
        thresholdHeight =
          window.innerHeight <= MAX_WINDOW_HEIGHT
            ? window.innerHeight
            : MAX_WINDOW_HEIGHT;
      }

      const scrollTop = document.documentElement.scrollTop;

      if (isSp) {
        setIsSticky(scrollTop >= pageInnerNavigatorTop);
        setIsTouchHeader(scrollTop >= pageInnerNavigatorTop);
      } else {
        setIsSticky(scrollTop >= thresholdHeight - PC_CAREER_HEADER_HEIGHT);
        setIsTouchHeader(
          scrollTop >= thresholdHeight - PC_CAREER_HEADER_HEIGHT
        );
      }
    }, 250),
    []
  );

  const calculateIndicatorPosition = (index: number) => {
    const target = document.querySelector(
      `[data-inner-page-index='data-inner-page-index-${index}']`
    );
    if (target) {
      const currentAnchorRect = target.getBoundingClientRect();

      const css: CSSProperties = {};
      cssWithPrefix(
        css,
        "transform",
        `translate3d(${currentAnchorRect ? currentAnchorRect.left : 0}px, 0, 0)`
      );
      cssWithPrefix(
        css,
        "width",
        `${currentAnchorRect ? currentAnchorRect.width : 94}px`
      );
      setIndicatorStyle(css);
    }
  };

  useEffect(() => {
    if (window.innerWidth < THLESHOLD_WIDTH_SP) {
      setIsSp(true);
    } else {
      setIsSp(false);
    }

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        const currentIndex = items.findIndex(item =>
          item.anchor.includes(entries[0].target.id)
        );

        setCurrentAnchorIndex(currentIndex);
      }
    });

    const targets = document.querySelectorAll(
      "[data-career-page-section-index]"
    );
    targets.forEach(target => {
      observer.observe(target);
    });

    const pageInnerNavigator = document.querySelector(
      "[data-page-inner-navigator=data-page-inner-navigator]"
    );
    if (pageInnerNavigator) {
      setPageInnerNavigatorTop(pageInnerNavigator.getBoundingClientRect().top);
    }

    const thresholdHeight =
      window.innerHeight <= MAX_WINDOW_HEIGHT
        ? window.innerHeight
        : MAX_WINDOW_HEIGHT;
    setInnerHeight(thresholdHeight);

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const thresholdHeight =
        window.innerHeight <= MAX_WINDOW_HEIGHT
          ? window.innerHeight
          : MAX_WINDOW_HEIGHT;
      setInnerHeight(thresholdHeight);
      calculateIndicatorPosition(currentAnchorIndex);

      if (window.innerWidth < THLESHOLD_WIDTH_SP) {
        setIsSp(true);
      } else {
        setIsSp(false);
      }
    });

    if (
      refs.current &&
      refs.current[currentAnchorIndex] &&
      refs.current[currentAnchorIndex].current
    ) {
      const currentAnchorRect =
        refs.current[currentAnchorIndex].current?.getBoundingClientRect();

      const css: CSSProperties = {};
      cssWithPrefix(
        css,
        "transform",
        `translate3d(${currentAnchorRect ? currentAnchorRect.left : 0}px, 0, 0)`
      );
      cssWithPrefix(
        css,
        "width",
        `${currentAnchorRect ? currentAnchorRect.width : 94}px`
      );
      setIndicatorStyle(css);
    }
  }, [currentAnchorIndex]);

  return (
    <div
      className={classNames(
        styles.PageInnerNavigator,
        isSticky ? styles.PageInnerNavigator_fixed : "",
        className
      )}
      // PCファーストビューではページの最下部に表示させる
      style={!isSticky && !isSp ? { top: `${innerHeight - 61}px` } : {}}
      data-page-inner-navigator="data-page-inner-navigator"
    >
      <ul
        className={classNames(
          styles.PageInnerNavigator__items,
          isTouchHeader ? styles.PageInnerNavigator__items__fixed : ""
        )}
      >
        {items.map((item, index) => (
          <li
            key={index}
            ref={refs.current[index]}
            data-inner-page-index={`data-inner-page-index-${index}`}
            className={styles.PageInnerNavigator__item}
          >
            <Link
              to={item.anchor}
              onClick={() => {
                setCurrentAnchorIndex(index);
              }}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
      <div
        className={styles.PageInnerNavigator__indicator}
        style={indicatorStyle}
      />
    </div>
  );
};

export default PageInnerNavigator;
