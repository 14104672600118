import React, {useEffect, useState} from "react";

const getWindowSize = () => typeof window === 'undefined' ? {width: 0, height: 0} : {width: window.innerWidth, height: window.innerHeight};
export const WindowSizeContext = React.createContext(getWindowSize());
export const WindowSizeProvider = ({children}) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const onResize = () => setWindowSize(getWindowSize());
    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        window.addEventListener('resize', onResize);
        requestAnimationFrame(onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);
    const value = {
        ...windowSize,
    };
    return <WindowSizeContext.Provider value={value}>{children}</WindowSizeContext.Provider>
}
