import React from "react";
import {format} from "date-fns";
import {Link} from "gatsby";
import {compact} from "lodash";
import * as styles from "./news.module.scss";

export class WpPost {
    authorName: string;
    categoryName: string;
    date: Date;
    excerpt: string;
    slug: string;
    srcSet: string;
    sizes: string;
    src: string;
    title: string;
    constructor({node: {author: {node: {name: authorName}}, categories: {nodes:[{name: categoryName}]},
        date, excerpt, title, slug, featuredImage: {node: {srcSet, sourceUrl, sizes}}}}) {
        this.authorName = authorName;
        this.categoryName = categoryName;
        this.date = new Date(date);
        this.excerpt = excerpt;
        this.slug = slug;
        this.srcSet = srcSet;
        this.sizes = sizes;
        this.src = sourceUrl;
        this.title = title;
    }
}

export const categoryClasses = {
    'お知らせ' : styles.categoryInformation,
    'プレスリリース' : styles.categoryPressRelease,
};

export const NewsItem = (post:WpPost) => <div className={[styles.content, styles.item].join(' ')}>
    <Link to={`/news/articles/${post.slug}`}>
        <div>
            <img className={styles.postImg} src={post.src} srcSet={post.srcSet}/>
            <h2>{post.title}</h2>
            <div className={styles.innercontainer}>
                <div dangerouslySetInnerHTML={{__html: post.excerpt}}/>
                <span className={styles.date}>{format(new Date(post.date), 'yyyy/MM/dd')}</span>
                <p className={compact([styles.frame, categoryClasses[post.categoryName]]).join(' ')}>{post.categoryName}</p>
                <p className={styles.name}>{post.authorName}</p>
            </div>
        </div>
    </Link>
</div>;