import React from "react";
import * as styles from "./TopPopupMenu.module.scss";
import serviceLinks from "../../home/data/all/service";
import { CustomLink } from "../../../atoms/link/CustomLink";

export const TopMenuServices = () => (
  <div className={styles.TopMenuService}>
    <p className={styles.TopMenuService__title}>Services</p>
    <div className={styles.TopMenuService__items}>
      <ul>
        <li>
          <CustomLink to={serviceLinks.elumild}>
            elumild<span>EC通販</span>
          </CustomLink>
        </li>
        <li>
          <CustomLink to={serviceLinks.evand}>
            Evand<span>人材</span>
          </CustomLink>
        </li>
        <li>
          <CustomLink to={serviceLinks.asfine}>
            Asfine<span>福祉</span>
          </CustomLink>
        </li>
        <li>
          <CustomLink to={serviceLinks.sc}>
            Suprieve Consulting<span>人事コンサル</span>
          </CustomLink>
        </li>
      </ul>
      <ul>
        <li>
          <CustomLink to={serviceLinks.rj}>
            Regen Japan<span>美容医療</span>
          </CustomLink>
        </li>
        <li>
          <CustomLink to={serviceLinks.artill}>
            Artill<span>アート</span>
          </CustomLink>
        </li>
        <li className={styles.TopMenuService__prepared}>
          GFI<span>金融</span>
        </li>
      </ul>
    </div>
  </div>
);
