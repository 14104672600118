import React, { useEffect, useState } from "react";
import * as styles from "../home/pc/index.module.scss";
import { Link } from "gatsby";
import { TwitterIcon } from "../../atoms/graphics/TwitterIcon";
import { InstagramIcon } from "../../atoms/graphics/InstagramIcon";
import { FacebookIcon } from "../../atoms/graphics/FacebookIcon";
import LogoBlack from "../../images/logo_black.png";
import { CustomLink } from "../../atoms/link/CustomLink";
import { YoutubeIcon } from "../../atoms/graphics/YoutubeIcon";
import { getLocationTreeInfo } from "../../utils/locationTree";
import classNames from "classnames";

function getNewsUrl(host: string): string {
  const isStg = host.includes("staging");
  const isLocal = host.includes("localhost");

  return isStg || isLocal
    ? "https://staging.suprieve.com/blog/"
    : "https://suprieve.com/blog/";
}

export default ({
  className = "",
  onPageTop = e => scrollTo({ top: 0, behavior: "smooth" }),
}) => {
  if (typeof window === "undefined") {
    return null;
  }

  const host = window.location.host;

  const [windowHref, setWindowHref] = useState("");
  const [windowProtocol, setWindowProtocol] = useState("");
  const [windowHost, setWindowHost] = useState("");

  useEffect(() => {
    setWindowHref(window.location.href);
    setWindowProtocol(window.location.protocol);
    setWindowHost(window.location.host);
  }, []);

  const locationTreeInfo = getLocationTreeInfo(
    windowHref,
    windowProtocol,
    windowHost
  );

  return (
    <footer
      className={classNames(className, styles.Footer)}
      style={{ pointerEvents: "auto" }}
    >
      <div className={styles.footer}>
        <h3 className={styles.pageTop} onClick={onPageTop}>
          Page Top
        </h3>

        <div className={styles.footerImg}>
          <Link to="/">
            <img src={LogoBlack} alt="Suprieve Holdings" height={32} />
          </Link>
        </div>

        <ul className={styles.Footer__breadCrumb}>
          {locationTreeInfo.map((location, index) => (
            <li key={index} className={styles.Footer__breadCrumbItem}>
              {index !== 0 ? (
                <p className={styles.Footer__breadCrumbItemTriangle} />
              ) : null}
              <p
                className={classNames(
                  styles.Footer__breadCrumbItemText,
                  index === locationTreeInfo.length - 1
                    ? styles.Footer__breadCrumbItemText_current
                    : ""
                )}
              >
                {index === locationTreeInfo.length - 1 ? (
                  <span>{location.location}</span>
                ) : (
                  <Link to={location.path}>{location.location}</Link>
                )}
              </p>
            </li>
          ))}
        </ul>

        <div className={styles.footerFlex}>
          <div className={styles.companyBox}>
            <h2>Company</h2>
            <ul className={styles.companyList}>
              <li>
                <Link to="/company/overview">Company Overview</Link>
              </li>
              <li>
                <Link to="/company/ceo-message">CEO Message</Link>
              </li>
              <li>
                <Link to="/company/board-member">Board Member</Link>
              </li>
              <li>
                <Link to="/company/vision">Vision</Link>
              </li>
              <li>
                <Link to="/company/culture">Culture</Link>
              </li>
              <li>
                <Link to="/company/history">History</Link>
              </li>
            </ul>
          </div>

          {/* Service */}
          <div className={styles.servicesBox}>
            <h2>Services</h2>
            <div className={styles.serviceLists}>
              <ul className={styles.serviceList}>
                <li>
                  <a href="https://elumild.com/">elumild</a>
                </li>
                <li>
                  <a href="https://evand.jp">Evand</a>
                </li>
                <li>
                  <a href="https://asfine.co.jp/">AsFine</a>
                </li>
                <li>
                  <a href="https://suprieve-consulting.com/">
                    Suprieve Consulting
                  </a>
                </li>
                <li>
                  <a href="https://regenjapan.com/">Regen Japan</a>
                </li>
                <li>
                  <a href="https://artill.jp/">Artill</a>
                </li>
                <li>
                  <a href="#">GFI</a>
                </li>
              </ul>
              <ul className={styles.serviceList}>
                <li>
                  <a href="https://suprieve.com/services/ad/">広告</a>
                </li>
                <li>
                  <a href="https://tokyolucci.jp/">東京ルッチ</a>
                </li>
                <li>
                  <a href="https://osakalucci.jp/">大阪ルッチ</a>
                </li>
                <li>
                  <a href="https://nontraining.jp/">ノントレ</a>
                </li>
                <li>
                  <a href="https://life-makepersonal.com/">
                    LIFE-MAKE PERSONAL
                  </a>
                </li>
                <li>
                  <a href="https://grandjoy.jp/">グランジョイクリニック</a>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.newsBox}>
            <h2>
              <a href={getNewsUrl(host)} target="_blank">
                News
              </a>
            </h2>
          </div>

          <div className={styles.careerBox}>
            <h2>Careers</h2>
            <ul className={styles.careerList}>
              <li>
                <Link to="/careers/new">新卒採用（総合職）</Link>
              </li>
              <li>
                <CustomLink to="https://evand.jp/recruit-top/" target="_blank">
                  中途採用
                </CustomLink>
              </li>
              {/* <li><Link to="/careers/mid">キャリア採用（総合・専門職）</Link></li> */}
              <li style={{ color: "#c2c2c2" }}>キャリア採用（総合・専門職）</li>
              <li>
                <Link to="/careers/diversity">障がい者雇用</Link>
              </li>
            </ul>
          </div>
          <div className={styles.contactBox}>
            <h2>
              <Link to="/contact">Contact</Link>
            </h2>
          </div>
          <div className="flex-1" />
        </div>
        <div className={styles.snsBox}>
          <div className="flex-1" />
          <div className={styles.snsSvg}>
            <CustomLink to="https://twitter.com/Suprieve8989">
              <TwitterIcon />
            </CustomLink>
          </div>
          <div className={styles.snsSvg}>
            <CustomLink to="https://www.facebook.com/suprieve/">
              <FacebookIcon />
            </CustomLink>
          </div>
          <div className={styles.snsSvg}>
            <CustomLink to="https://www.instagram.com/suprieve/">
              <InstagramIcon />
            </CustomLink>
          </div>
          <div>
            <CustomLink to="https://www.youtube.com/channel/UC24yp_X3thkh9dmEVxev1-w">
              <YoutubeIcon />
            </CustomLink>
          </div>
        </div>
        <div className={styles.Footer__privacyPolicy}>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
        <div className={styles.footerFlex}>
          <div className="flex-1" />
          <div className={styles.footerCopyright}>
            <h2>
              Copyright &copy; Suprieve Holdings Inc. All Rights Reserved.
            </h2>
          </div>
        </div>
      </div>
    </footer>
  );
};
